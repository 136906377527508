import * as React from 'react';
import { graphql } from 'gatsby';
import '../../pages/styles.scss';
import {
  BasePageData,
  BasePageFooter,
  BasePageHeader,
} from '../../helpers/base-page';
import { MarkdownRemark } from '../../models/markdown-remark';
import styled from 'styled-components';
import Background from '../../images/impact-background.png';
import { device } from '../../models/devices';
import ReactMarkdown from 'react-markdown';
import { Markdown } from '../shared/markdown';
import AvatarPlaceholder from '../../images/man.svg';
import readingTime from 'reading-time';

interface ArticlePageData extends BasePageData {
  category: MarkdownRemark<{
    name: string;
    urlFriendlyName: string;
  }>;
  article: MarkdownRemark<{
    title: string;
    subTitle: string;
    image: string;
    Body: string;
    createdDate: string;
  }>;
  author: MarkdownRemark<{
    firstName: string;
    lastName: string;
    image: string;
  }>;
}

const Container = styled.section`
  background: url(${Background}) no-repeat top center;
  background-size: contain;
  padding-bottom: 152px;
  padding-top: 184px;

  @media screen and ${device.tablet} {
    background-size: auto 748px;
    padding: 115px 24px 80px;
  }
`;

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const Main = styled.article`
  padding: 62px 100px 50px 99px;
  box-shadow: 6px 12px 34px 0 rgba(0, 0, 0, 0.04);
  background: white;
  border-radius: 30px;
  max-width: 1170px;
  box-sizing: border-box;
  margin: 0 auto;
  z-index: 1;
  position: relative;

  main {
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  @media screen and ${device.tablet} {
    padding: 32px 24px;
  }
`;

const Header = styled.header`
  width: 100%;
  margin: 0 auto 42px;

  h1 {
    max-width: 600px;
    margin: 0 auto;
    font-size: 48px;
    color: black;
    font-weight: 500;
    line-height: 1.37em;
    letter-spacing: -0.3px;
    text-align: center;
  }

  h2 {
    max-width: 600px;
    font-size: 18px;
    font-weight: 500;
    color: #7b85a0;
    text-align: center;
    margin: 0 auto 30px;
  }

  @media screen and ${device.tablet} {
    h1 {
      font-size: 32px;
      line-height: 1.5;
    }

    max-width: initial;
    margin-bottom: 19px;
  }
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const AuthorName = styled.a`
  font-size: 12px;
  color: #292d34;
  font-weight: 600;
  text-decoration: none;
`;

const Time = styled.span`
  color: #7b85a0;
  font-size: 12px;
  display: block;

  a {
    text-decoration: none;
  }

  @media screen and ${device.tablet} {
    margin-bottom: 10px;
  }
`;

const ArticlePage = ({ data }: { data: ArticlePageData }): JSX.Element => {
  const article = data.article.edges[0].node.frontmatter;
  const category = data.category.edges[0].node.frontmatter;
  const author = data.author.edges[0].node.frontmatter;
  const createdDate = new Date(article.createdDate).toLocaleDateString(
    'en-US',
    {
      weekday: undefined,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  );

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={article.subTitle}
        siteDescriptionTitle={article.title}
        siteImage={article.image}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        contentType={'blog-post'}
      />
      <Container>
        <Wrapper>
          <Main>
            <Header>
              <Time>
                <a href={`/blog/${category?.urlFriendlyName}`}>
                  {category.name}
                </a>{' '}
                | {readingTime(article.Body).text}
              </Time>
              <h1>
                <ReactMarkdown>{article.title}</ReactMarkdown>
              </h1>
              <h2>
                <ReactMarkdown>{article.subTitle}</ReactMarkdown>
              </h2>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Avatar
                  style={{ marginRight: 20 }}
                  src={
                    author.image?.length > 0 ? author.image : AvatarPlaceholder
                  }
                  alt={`${author.firstName} ${author.lastName}`}
                />
                <AuthorName
                  href={`/blog/authors/${author.firstName.toLowerCase()}-${author.lastName.toLowerCase()}`}
                >
                  {author.firstName} {author.lastName}
                </AuthorName>
                <span style={{ marginLeft: 'auto', color: '#292d34' }}>
                  {createdDate}
                </span>
              </div>
            </Header>
            <main>
              <img
                src={article.image}
                alt=""
                width={'100%'}
                style={{ marginBottom: 40 }}
              />
              <Markdown>{article.Body}</Markdown>
            </main>
          </Main>
        </Wrapper>
      </Container>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={
          data.contact.edges[0].node.frontmatter.inputPlaceholder
        }
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default ArticlePage;

export const pageQuery = graphql`
  query (
    $categoryUrl: String!
    $articleUrl: String!
    $authorFirstName: String!
    $authorLastName: String!
  ) {
    ...BaseData
    category: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/^(?!.*/site/).*categories/" }
        frontmatter: { urlFriendlyName: { eq: $categoryUrl } }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
            urlFriendlyName
          }
        }
      }
    }
    article: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/^(?!.*/site/).*blogs/" }
        frontmatter: { urlFriendlyName: { eq: $articleUrl } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            subTitle
            image
            Body
            createdDate
          }
        }
      }
    }
    author: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/^(?!.*/site/).*authors/" }
        frontmatter: {
          firstName: { eq: $authorFirstName }
          lastName: { eq: $authorLastName }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            firstName
            lastName
            image
          }
        }
      }
    }
  }
`;
